import React from 'react';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';

export default function Home() {
    const bgUnits = ['cavalry-archer', 'heavy-cavalry', 'axeman', 'swordsman'];
    const bgImage = bgUnits[Math.floor(Math.random() * bgUnits.length)];

    const style = `
        @media (min-width: 1024px) {
            #jumbotron-background {
                background-image: url('/assets/img/unit/${bgImage}.svg');
                background-repeat: no-repeat;
                background-position: 130% 13%;
                background-size: 60%;
                border-radius: 6px;
            }
            .jumbotron {
                background-color: rgba(0, 0, 0, 0.7);
            }
        }
    `;

    return (
        <React.Fragment>
            <Helmet>
                <title>Feudarium - Webová hra</title>
                <style>{style}</style>
            </Helmet>
            <Layout>
                <div id="jumbotron-background">
                    <div className="jumbotron">
                        <h1>Feudarium</h1>
                        <p className="lead">Feudarium je klasická webová strategická hra so stredovekou tématikou.</p>

                        <p className="lead hidden-xs">
                            Tvojou úlohou je rozvíjať malé mestečko, zabezpečiť mu dostatok surovín a bezpečnosť pred
                            ostatnými hráčmi. Keď budeš dostatočne odvážny, tak sa môžeš vybrať na dobyvateľské výpravy,
                            ovládnuť väčšinu sveta a vyhrať hru.
                        </p>
                        <p className="lead">
                            Hra je aktuálne vo vývoji a nie je možné sa registrovať. Môžeš si však niečo o nej prečítať{' '}
                            <a href="https://blog.feudarium.com/">na blogu.</a>
                        </p>
                        <a href="https://blog.feudarium.com/" className="btn btn-lg btn-success btn-block">
                            Sledovať vývoj na blogu <span className="glyphicon glyphicon-chevron-right"></span>
                        </a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 text-center">
                        <a href="/assets/img/screenshot/town.png">
                            <img
                                className="img-circle"
                                src="/assets/img/screenshot/town@140x140.png"
                                alt="Detail mesta"
                                height="140"
                                width="140"
                            />
                        </a>
                        <h2>Rozvíjaš mesto</h2>
                        <p>
                            Začínaš ako vládca malého mesta, ktorý svoje mesto rozvíja stavbou a vylepšovaním budov.
                            Tvojím cieľom je zabezpečiť si suroviny pre rozvoj a budovať obranu proti ostatným hráčom.
                        </p>
                    </div>
                    <div className="col-lg-4 text-center">
                        <a href="/assets/img/screenshot/map.png">
                            <img
                                className="img-circle"
                                src="/assets/img/screenshot/map@140x140.png"
                                alt="Herná mapa"
                                height="140"
                                width="140"
                            />
                        </a>
                        <h2>Vytváraš spojenectvá</h2>
                        <p>
                            Okrem teba sa v hernom svete nachádzajú aj ďalší hráči, s ktorými môžeš buď súperiť alebo sa
                            s nimi spojiť v cieli o ovládnutie sveta.
                        </p>
                    </div>
                    <div className="col-lg-4 text-center">
                        <a href="/assets/img/screenshot/barracks.png">
                            <img
                                className="img-circle"
                                src="/assets/img/screenshot/barracks@140x140.png"
                                alt="Plánovanie útoku"
                                height="140"
                                width="140"
                            />
                        </a>
                        <h2>Bojuješ s ostatnými</h2>
                        <p>
                            Vo Feudariu je veľmi dôležitá armáda. Bez nej sa nevieš brániť pred útokmi a iba s ňou si
                            vieš podmaniť nové mestá. Mal by si jej možnosti dobre poznať a vedieť ju správne používať.
                        </p>
                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-8">
                        <div className="row">
                            <div className="col-xs-12">
                                <h2>
                                    <a href="https://blog.feudarium.com" target="_blank" rel="noreferrer">
                                        Články na blogu
                                    </a>
                                </h2>

                                <div className="blog-article">
                                    <h3>
                                        <a
                                            href="https://blog.feudarium.com/2017/05/08/spusteny-svet-beta-1.html"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Spustenie herného sveta Beta 1 <small>(08.05.2017)</small>
                                        </a>
                                    </h3>

                                    <p>
                                        Táto správa prichádza neskoro, ale lepšie, ako nikdy :) 27. apríla sa vo
                                        Feudariu spustil nový herný svet. Pôvodne mal obsahovať viac novej funkcionality
                                        oproti predchádzajúcemu svetu, ale nepodarilo sa mi to všetko stihnúť
                                        naprogramovať.{' '}
                                        <a
                                            href="https://blog.feudarium.com/2017/05/08/spusteny-svet-beta-1.html"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Prečítať celý článok.
                                        </a>
                                    </p>
                                </div>

                                <div className="blog-article">
                                    <h3>
                                        <a
                                            href="https://blog.feudarium.com/2017/05/08/prezentacia-slavcon.html"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Prezentácia o Feudariu na Slavcone <small>(08.05.2017)</small>
                                        </a>
                                    </h3>

                                    <p>
                                        Posledný aprílový víkend (a ešte piatok k tomu) sa v Bratislave konal festival
                                        Slavcon a ja som tam mal prezentáciu o Feudariu a tvorbe webových strategických
                                        hier.{' '}
                                        <a
                                            href="https://blog.feudarium.com/2017/05/08/prezentacia-slavcon.html"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Prečítať celý článok.
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    );
}
